import React from 'react'

const Research = () => {
  return (
    <div>
      <h1>
        Research Section is coming soon
      </h1>
    </div>
  )
}

export default Research
